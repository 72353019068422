import React, { useState, useEffect } from 'react';
import { BsCheckCircle, BsCircle } from "react-icons/bs";
import { useSelector } from 'react-redux'; // Importe useSelector
import { debug, isDev } from './../../../utils';

const PasswordChecker = ({ password, isValid }) => {
  const config = useSelector((state) => state.config);

  // Gérer le cas où config.form_rules.password.include pourrait ne pas être défini

  const specialChars = config?.form_rules?.password?.include?.special || ""; // Gérer l'absence de special
  const specialRegex = specialChars ? new RegExp("[" + specialChars + "]") : null;


  useEffect(() => {
    const passwordRegex = new RegExp(config?.form_rules?.password?.regex.slice(1, -1)); // Regex pour mot de passe
    isValid(passwordRegex.test(password)); // Valider le mot de passe
  }, [config, password,]); // isValid// Ajout de passwordRegex dans les dépendances

  return (
    <div>
      {config?.form_rules?.password &&
        <ul className='password-features small'>
          <li className='d-flex align-items-center'>
            {password.length >= config.form_rules.password.length.min && password.length <= config.form_rules.password.length.max ? <BsCheckCircle className="ok" /> : <BsCircle className="ko" />}
            &nbsp;Entre {config.form_rules.password.length.min} et {config.form_rules.password.length.max} caractères
          </li>
          {config.form_rules.password.include?.uppercase &&
            <li className='d-flex align-items-center'>
              {password.match(/[A-Z]/) ? <BsCheckCircle className="ok" /> : <BsCircle className="ko" />}&nbsp;Une majuscule minimum
            </li>
          }
          {config.form_rules.password.include?.lowercase &&
            <li className='d-flex align-items-center'>
              {password.match(/[a-z]/) ? <BsCheckCircle className="ok" /> : <BsCircle className="ko" />}&nbsp;Une minuscule minimum
            </li>
          }
          {config.form_rules.password.include?.number &&
            <li className='d-flex align-items-center'>
              {password.match(/[0-9]/) ? <BsCheckCircle className="ok" /> : <BsCircle className="ko" />}&nbsp;Un chiffre minimum
            </li>
          }
          {specialRegex &&
            <li className='d-flex align-items-center'>
              {specialRegex?.test(password) ? <BsCheckCircle className="ok" /> : <BsCircle className="ko" />}&nbsp;Un caractère spécial ({specialChars}) minimum
            </li>
          }
        </ul>
      }
    </div>
  );
};

export default PasswordChecker;
