import axios from 'axios';
import { swalAlert } from './utils';

const api_url = 'https://amd.sys8.animanet.eu/api';

const API = axios.create({
  baseURL: api_url,
  timeout: 100000,
  handleError: true // Défini globalement
});
API.getUrl = () => api_url;
API.getRequestHeaders = (route) => {
  const headers = {};
  const token = route.startsWith('/admin')
    ? localStorage.getItem('adminToken')
    : localStorage.getItem('token');

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};
API.interceptors.request.use(
  (config) => {
    config.headers = { ...config.headers, ...API.getRequestHeaders(config.url) };
    return config;
  },
  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => response, // Réponse réussie
  (error) => {
    if (error.response) {
      // Si `handleError` est défini à `false`, on remonte l'erreur sans la gérer
      if (API.handleError === false) {
        return Promise.reject(error);
      }

      // Gérer l'erreur 404 (Ressource non trouvée)
      if (error.response.status === 404) {
        console.warn('Erreur 404: Ressource non trouvée', error.response.config.url);
        const requestedUrl = error.response.config.url;
        swalAlert(`La ressource <br>''${requestedUrl}''<br> est introuvable (Erreur 404).`);
        // Retourne une valeur spécifique pour l'erreur 404
        return Promise.resolve({ data: null, status: 404 });
      }

      // Remonter toutes les autres erreurs pour que le composant les gère
      console.error('Erreur de réponse:', error.response);
      return Promise.reject(error); // Remonter l'erreur vers le composant appelant
    } else {
      // Gérer les erreurs réseau ou autres
      console.error('Erreur de réseau ou autre:', error.message);
      return Promise.reject(error); // Remonter l'erreur vers le composant appelant
    }
  }
);

export default API;
