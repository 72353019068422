// src/components/homes/HomeDefault.js
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Importe useSelector
import { debug } from '../../utils'
import UserSignInModal from '../user/UserSignInModal';


import Modal from 'react-bootstrap/Modal';
import { FaBeer } from 'react-icons/fa';
import API from '../../api'
import './home-default.css';
const HomeDefault = () => {
  const user = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const options = {

  }
  const onTabSwitch = () => { }
  useEffect(() => {
  }, [user])
  return (
    <>
      {debug(user, false)}
      {!user.isSignedIn &&
        <div id="homepage-default" className=' py-5'>
          <article className="">
            <div className="background">
            </div>

            <div className="article-inner">
              <h1>
                Bienvenue dans l'extranet<br /> AMD JAEGER !
              </h1>
              <div className="text-center pt-5">
                <button className="btn btn-green btn-lg" onClick={e => setShowModal(true)}>CONNEXION</button>
                <UserSignInModal options={options} show={showModal} />
              </div>

            </div>

          </article>

        </div>
      }
      {user.isSignedIn &&
        <Navigate to='/home' replace />
      }
    </>
  )
};
export default HomeDefault;
/*


          

            <div className="darkener">
            </div>

            <div className="svg-over">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1600 900" enable-background="new 0 0 1600 900" xml:space="preserve">
                <polygon fill="#008788" points="1600,900 0,900 0,0 1254.008,0 "></polygon>
              </svg>
            </div>

<div className="text-over">
<h1>
  <span>
    FAISCEAUX ELECTRIQUES POUR L’AUTOMOBILE
  </span>
</h1>
<h2 className="title"><span>Faisceaux électriques, kits et accessoires JAEGER Automotive</span></h2>
<div className="text">
  <p style="text-align: justify;">Nous commercialisons auprès de distributeurs français une gamme complète de faisceaux électriques, de kits, d'accessoires pouvant équiper toutes les marques de véhicules automobiles du marché.</p>

</div>
</div>


</article>
*/