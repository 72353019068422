// OrderItem.js
// update `app_orders_items` set`itUserId`="user56519edd53c76" , `itOrderId` = "" WHERE `itId` < "IT5795e2d981feb";
import { useState, useEffect } from 'react';
import API from './../../api';
import Swal from 'sweetalert2'
import { formatPrice } from './../../utils';
import { useTranslation } from 'react-i18next';
import { BiTrashAlt } from "react-icons/bi";
import { FaQuestionCircle } from 'react-icons/fa';
import InputQuantity from './../helpers/InputQuantity/InputQuantity';
import './order-items.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import ProdCodeStock from '../products/ProdCodeStock';
function OrderItem({ item, onUpdate, onClick, editable = true }) {
  const { t } = useTranslation();// ['orders', 'common',]
  const confirmRemove = () => {
    Swal.fire({
      grow: false,
      html: t('Confirm deletion?'),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: t('Yes'),
      cancelButtonText: t('No'),
      showConfirmButton: true,
      customClass: {
        htmlContainer: 'pt-5',
        confirmButton: 'btn-sm btn-green me-5',
        cancelButton: 'btn-sm',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onUpdate({ ...item, itQuantity: 0 })
      } else if (result.isDismissed) {
      }
    });
  };
  return (
    <tr className="order-item">
      <td className='prodRef text-center'>{item.itProdRef}<br /><small>{item.product.prodCatalogue}</small></td>
      <td className='prodMarque '>
        <span className='pointer' onClick={e => onClick(item)}>
          {item.product.prodCatalogue == 'kit' ?
            <>{item.product.prodTitre}</>
            :
            item.itProdId ?
              <> {item.product.prodMarque} < br /> {item.product.prodModele} < br /> {item.product.prodAnnee}</>
              :
              <>{item.itProdCatalogue} <FaQuestionCircle size={14} className="text-gray-9" title="Cliquer pour associer un constructeur et un modèle" /></>
          }
        </span>
      </td>
      <td className='price text-center'>{formatPrice(item.itMbrUnitPriceET)}</td>
      <td className='itQuantity text-center'>
        {editable ?
          <InputQuantity quantity={item.itQuantity} disabled={false} onUpdate={quantity => onUpdate({ ...item, itQuantity: quantity })} />
          :
          <span>{item.itQuantity} </span>
        }
      </td>
      <td className='price  text-center'>{formatPrice(item.itMbrTotalPriceET)}</td>
      <td className='price  text-center'>{formatPrice(item.itVATAmount)}</td>
      <td className='price  text-center'>{formatPrice(item.itMbrTotalPriceIT)}</td>
      {editable && <td className='text-center'>
        <span className='pointer' onClick={confirmRemove}><BiTrashAlt /></span>
      </td>
      }
      <td className='itProdCodeStock text-left px-0 ' style={{ paddingTop: '0.9rem' }}>
        <ProdCodeStock codeStock={item.itProdCodeStock} label={false} />
      </td>
    </tr >
  );
}
export default OrderItem;
