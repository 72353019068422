// CustomerEdit.js
import { useState, useEffect } from 'react';
import API from './../../../api';
import AdminCustomerProfile from './AdminCustomerProfile';
import AdminCustomerPassword from './AdminCustomerPassword';
import AdminCustomerAdmin from './AdminCustomerAdmin';
import { useTranslation } from 'react-i18next';
import './admin-customer-edit.css';
import './../../../css/forms.css';
import { swalAlert } from '../../../utils';
import AdminOrdersList from '../../orders/admin/AdminOrdersList';
function AdminCustomerEdit({ customer, onCreated, onUpdated }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('profile')
  const [editedCustomer, setEditedCustomer] = useState({})
  const [formStatus, setFormStatus] = useState('')
  useEffect(() => {
    setActiveTab('profile');
    const payload = {};
    if (customer.userId == '') {
      payload.userStatus = 'actif';
      payload.userType = 'cust';
      payload.userName = '';
      payload.userFirstname = '';
      payload.userCompName = '';
      payload.userCity = '';
      payload.password = '';
      payload.userCatalogs = ['Faisceau', 'kit'];
      payload.specialPrices = [];
      payload.userOrderEnabled = '1';
      payload.userFaqRole = "reader";
      payload.userDiscout_Faisceau = 0;
      payload.userDiscout_Attelage = 0;
      payload.userDiscout_Kit = 0;
    }
    setEditedCustomer({
      ...customer,
      ...payload,
    });
  }, [customer])
  const handleCustomerChange = (tab, payload) => {
    switch (tab) {
      case 'specialPrices':
        setEditedCustomer({ ...editedCustomer, specialPrices: payload });
        break;
      default:
        setEditedCustomer({ ...editedCustomer, ...payload })
    }
  }
  const handleSave = async () => {
    setFormStatus('saving');
    let response;
    try {
      if (editedCustomer.userId) {
        response = await API.put('/admin/customer/' + editedCustomer.userId, editedCustomer);
        setEditedCustomer(response.data);
        onUpdated(response.data)
      } else {
        response = await API.post('/admin/customer', editedCustomer);
        setEditedCustomer(response.data);
        onCreated(response.data)
      }

    } catch (error) {
      swalAlert(error.response.data.error.message, error);
    } finally {
      setFormStatus('');
    }

    // window.setTimeout(() => { setFormStatus(''); }, 500);
  }
  return (
    <div id="CustomerEdit">
      <ul className='nav nav-tabs mb-3'>
        <li className='nav-item'><a className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`} onClick={e => setActiveTab('profile')} >Profile <span className='required'></span></a></li>
        <li className='nav-item'><a className={`nav-link ${activeTab === 'admin' ? 'active' : ''}`} onClick={e => setActiveTab('admin')}>Admnistration <span className='required'></span></a></li>
        <li className='nav-item'><a className={`nav-link ${activeTab === 'orders' ? 'active' : ''}`} onClick={e => setActiveTab('orders')}>Commandes </a></li>
        <li className='nav-item'><a className={`nav-link ${activeTab === 'password' ? 'active' : ''}`} onClick={e => setActiveTab('password')}>
          Mot de passe
          {(editedCustomer.userId === '' || (editedCustomer.password?.length > 0 && editedCustomer.passwordIsValid == false)) && <span className='required'></span>}
        </a>
        </li>
        <li className='nav-item ms-auto'>
          <button className='btn btn-green' onClick={handleSave} disabled={
            (editedCustomer.password?.length > 0 && !editedCustomer.passwordIsValid)
            || (editedCustomer.userId === '' && editedCustomer.password?.length === 0)
          } >
            {formStatus == 'saving' && <span class="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>}
            Sauvegader
          </button>
        </li>
      </ul>
      <div className=' tab-contents'>
        {activeTab === 'profile' && <AdminCustomerProfile customer={editedCustomer} onChange={cust => handleCustomerChange('profile', cust)} />}
        {activeTab === 'admin' && <AdminCustomerAdmin customer={editedCustomer} onChange={cust => handleCustomerChange('admin', cust)} />}
        {activeTab === 'orders' && <AdminOrdersList query={{ 'userId:eq': editedCustomer.userId }} title={false} selector={false} thead={false} columns={['orderDate', 'orderRef', 'articles', 'delivery', 'download']} />}
        {activeTab === 'password' && <AdminCustomerPassword onChange={password => handleCustomerChange('password', password)} defaultValue={editedCustomer.password} />}
      </div>
      #{formStatus}#
      {false && <pre>{JSON.stringify(editedCustomer, null, 2)}</pre>}
      {false && (editedCustomer.passwordIsValid ? 'Password is valid' : 'Password is NOT valid')}
    </div >
  );
}
export default AdminCustomerEdit;
/*
{activeTab === 'specialPrices' && <AdminCustomerSpecialPrices userId={customer.userId} onChange={specialPrices => handleCustomerChange('specialPrices', specialPrices)} />}
<li className='nav-item'><a className={`nav-link ${activeTab === 'specialPrices' ? 'active' : ''}`} onClick={e => setActiveTab('specialPrices')}>Prix spéciaux</a></li>
*/