import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import API from './../../api';
import { BiBook } from 'react-icons/bi';
import { AiOutlineUser, AiOutlineShoppingCart, AiOutlineArrowUp, AiOutlineHome, AiOutlineMenu } from 'react-icons/ai'; // Import de l'icône Home
import './admin-header.css';
import './admin-header.css'
const AdminHeader = React.memo(() => {
  const user = useSelector((state) => state.user);
  const config = useSelector((state) => state.config);
  const { t } = useTranslation();
  const navLinks = [
    {
      to: "/extradmin",
      icon: <AiOutlineHome size={30} title="Accueil" />,
      label: "Accueil"
    },
    {
      to: "/extradmin/customers",
      icon: <AiOutlineUser size={30} title="Accéder au fichier clients" />,
      label: "Fichier Clients"
    },
    {
      to: "/extradmin/orders",
      icon: <AiOutlineShoppingCart size={30} title="Accéder aux commandes" />,
      label: "Commandes"
    },
    {
      to: "/extradmin/products",
      icon: <BiBook size={30} title="Accéder aux catalogues produits" />,
      label: "Produits"
    }
  ];
  // const backoffice_url = window.sessionStorage.getItem('backoffice_url');
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <header className={`App-header ${menuOpen ? 'responsive-menu-on' : ''}`} id="admin-header">
      <div id="header-main">

        <div className="col flex-grow-0 py-1 text-center" id="header-main-left" >
          <Link className="logo" to="/extradmin">
            <img src="/img/logo.png" alt="amd jaeger" />
          </Link>
          <span className="fs-8 text-gray-9 text-uppercase">Backoffice extranet</span>
        </div>
        <div className="col flex-grow-1" id="header-main-center">
          {/* Burger Menu for mobile */}
          {/* Navigation links with toggle for mobile */}
          <nav className={`nav-links ${menuOpen ? 'active' : ''}`}>
            {navLinks.map((link, index) => (
              <Link to={link.to} className="btn btn-green" key={index}>
                {link.icon}
              </Link>
            ))}
          </nav>
        </div>
        <div className="text-end" id="header-main-right" style={{ width: '180px' }}>

          <a href='http://admin.amd-jaeger.fr' className="small w-100 d-flex align-items-center justify-content-end gap-2">
            RETOUR BACKOFFICE
            <AiOutlineArrowUp size={24} />
          </a>

        </div>

      </div>
      <button className="burger-menu" onClick={toggleMenu}>
        <AiOutlineMenu size={30} />
      </button>
      <div id="header-responsive-menu">
        <ul className="list-unstyled">
          {navLinks.map((item, index) => (
            <li className="py-2" key={index}>
              <Link to={item.to} className="" onClick={() => setMenuOpen(false)}>
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
});
export default React.memo(AdminHeader);
