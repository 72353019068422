// Thread.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from './../../utils'
import API from './../../api';
import { useParams, useNavigate } from 'react-router-dom';
import Picture from './../helpers/Picture/Picture';
import { debug } from './../../utils';
import { Button, Modal } from 'react-bootstrap';
import { BiPencil, BiTrashAlt, BiSolidDisc, BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
// import Truncate from 'react-truncate-html';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from './../../redux/userSlice';
import './thread.css';
// const striptags = require('striptags');
import striptags from 'striptags';
import Swal from 'sweetalert2'
import PostEdit from './PostEdit';
function Thread() {
  const dispatch = useDispatch();
  const { ref } = useParams();
  const [posts, setPosts] = useState([]);
  const [postToEdit, setPostToEdit] = useState(null);
  useEffect(() => {
    dispatch(fetchUser());
    try {
      API.get(`/faq/thread/${ref}`).then(response => {
        setPosts(response.data);
        if (response.data.length == 0) {
          setPostToEdit({ postRef: ref, postText: '', postPictures: [] });
        }
        // setMe(response.data[0].postText)
      });
    }
    catch (e) {
    }
  }, [])
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [attachmentToShow, setAttachmentToShow] = useState(null);
  const handleClose = () => {
    setPostToEdit(null);
  }
  const handlePostSaved = (post) => {
    if (posts.find((p) => p.postId === post.postId)) {
      setPosts(posts.map((p) => p.postId === post.postId ? post : p));
    } else {
      if (post.postStatus !== 'unvalidated') {
        setPosts([...posts, post]);
      } else {
        Swal.fire({
          title: 'Merci !',
          text: 'Votre contribution a été transmise aux adminsitrateurs pour validation.',
          icon: '', // Vous pouvez changer l'icône (info, warning, error, success, etc.)
          showCancelButton: false,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn-sm btn-green me-5',
          },
        });
      }
    }
    setPostToEdit(null);
  }
  const handleAddPost = () => {
    setPostToEdit({ postRef: ref, postText: '', postPictures: [] });
  }
  const handleDeletePost = async (post) => {
    Swal.fire({
      grow: false,
      html: t('Confirm deletion?'),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: t('Yes'),
      cancelButtonText: t('No'),
      showConfirmButton: true,
      customClass: {
        htmlContainer: 'pt-5',
        confirmButton: 'btn-sm btn-green me-5',
        cancelButton: 'btn-sm',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await API.delete(`/faq/post/${post.postId}`);
          setPosts(posts.filter((p) => p.postId != post.postId));
        } catch (error) {
        }
      } else if (result.isDismissed) {
      }
    });
  }
  const handleAttachmentClick = (attachment) => {
    console.log(attachment)
  }
  return (
    <div id="thread">

      <div className='page-title'>
        <div className='container'>
          <div className='row align-items-center '>
            <div className='col-4  text-start'>
              <BiArrowBack className="me-2" /><Link to="/faq" className="text-white">Retour</Link>
            </div>
            <div className='col-8 col-sm-4 text-center'>
              <h1 className='m-0 p-0'>{t('Reference')} {ref}</h1>
            </div>
          </div>

        </div>
      </div>





      <div className='container'>
        <table className='table table-striped'>
          <tbody>
            {posts.map(post => (
              (post.postStatus === 'validated' || user.roles?.faq === 'admin') &&
              <tr key={post.postId} className='post'>
                <td className='p-3 pt-4'>
                  <span className='text-green'>{post.postAuthor}</span><br />
                  <small>{formatDate(post.postCreatedAt)}</small>
                </td>
                <td className='px-3'>
                </td>
                <td className='p-3 pt-4'>
                  <div dangerouslySetInnerHTML={{ __html: post.postText }}></div>
                  <ul className='attachments p-0 m-0'>
                    {true && post.postPictures.map((attachment, idx) => (
                      <li key={idx} onClick={e => setAttachmentToShow(attachment)} className='pointer'>
                        <Picture src={`${attachment}?r=${post.postUpdatedAt}`} size='maxh=180' />
                      </li>
                    ))}
                  </ul>
                </td>
                {(user.roles?.faq === 'admin') &&
                  <>
                    <td className='p-3 pt-4 text-center'>
                      <a title={t(post.postStatus)}>
                        <BiSolidDisc size="18" color={post.postStatus === 'unvalidated' ? '#dc3545' : post.postStatus === 'draft' ? '#6c757d' : '#198754'} />
                      </a>
                    </td>
                    <td className='p-3 pt-4 text-center'>
                      <a title={t(post.postStatus)}>
                        <BiPencil size="18" onClick={e => setPostToEdit(post)} />
                      </a>
                    </td>
                  </>}
              </tr>
            ))}
          </tbody>
        </table>
        {postToEdit ?
          (<PostEdit post={postToEdit} onSaved={handlePostSaved} />)
          : (
            <div className='text-center py-5'>
              <button className='btn btn-green' onClick={handleAddPost}>
                Poser une question ou ajouter une contribution
              </button>
            </div>)
        }
        {debug(user)}
      </div >

    </div >
  );
}
export default Thread;
/*





      </div >







        <Modal show={attachmentToShow} onHide={e => setAttachmentToShow(null)} className='modal-attachment-show modal-lg'>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className='attachment-wrapper text-center d-flex flex-column align-items-center justify-content-center '>
              {attachmentToShow &&
                <Picture src={`${attachmentToShow}?r=1`} size='maxw=800' />
              }
            </div>
          </Modal.Body>
        </Modal>
*/