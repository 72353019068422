import { useEffect, useState } from 'react';
import Uploader from '../../helpers/uploader/Uploader';
import { useSelector } from 'react-redux';
import API from '../../../api';
import { debug, swalAlert } from '../../../utils';
import { MdDelete } from "react-icons/md";
import { MdCancel } from "react-icons/md";

const initialSelectedColumns = {
  ref: { label: 'Référence', value: '' },
  price: { label: 'Prix HT', value: '' },
  stockQty: { label: 'Stock', value: '' },
  stockPlace: { label: 'Emplacement', value: '' },
}
if (window.localStorage.getItem('products_imports_columns') === null) {
  window.localStorage.setItem('products_imports_columns', "{}")
}

const AdminHomeUpdate = () => {
  const config = useSelector((state) => state.config);
  // Initialisationde l'état, ici un tableau pour gérer les types d'update sélectionnés
  const [what, setWhat] = useState(['stocks']);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [fileHeaders, setFileHeaders] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState({ ...initialSelectedColumns });


  useEffect(() => {
    console.clear();
    console.log('ICI ', uploadedFile)
    if (uploadedFile?.key) {

      const memory = JSON.parse(window.localStorage.getItem('products_imports_columns'));
      console.log(memory)
      console.log('----', uploadedFile.key);
      if (memory[uploadedFile.key]) {
        console.log('Fête des grands meres ? Qui commence ????');
        const selectedColumnsCopy = { ...selectedColumns };
        for (let field in memory[uploadedFile.key]) {
          console.log(field)
          selectedColumnsCopy[field].value = memory[uploadedFile.key][field] ? memory[uploadedFile.key][field] : '';
        }
        setSelectedColumns(selectedColumnsCopy);
      }

    }

  }, [uploadedFile]);

  // Gestion des clics sur les cases à cocher
  const handleCheckBoxClick = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      // Ajoute le type d'update sélectionné si la case est cochée
      setWhat((prevState) => [...prevState, name]);
    } else {
      // Retire le type d'update si la case est décochée
      setWhat((prevState) => prevState.filter(item => item !== name));
    }
  };
  const onUploadStart = () => {
    setFetching(true);
    setFileHeaders(null);
    setSelectedColumns({ ...initialSelectedColumns });
    // Logic de démarrage d'upload si nécessaire
  };
  const onUploadSuccess = (data) => {
    console.log(data)
    const fileKey = data.name.replace(/\s/g, '_')
    setUploadedFile({ path: data.path, name: data.name, key: fileKey })
    setFetching(false);
    if (data.headers) {
      setFileHeaders(data.headers);
    }
  }


  const handleColumnSelect = (field, value) => {
    console.log(field, value)
    const memory = JSON.parse(window.localStorage.getItem('products_imports_columns'));
    if (!memory[uploadedFile.key]) {
      memory[uploadedFile.key] = {}
    }
    memory[uploadedFile.key][field] = value;
    window.localStorage.setItem('products_imports_columns', JSON.stringify(memory))










    const selectedColumnsCopy = { ...selectedColumns }
    selectedColumnsCopy[field].value = value;
    setSelectedColumns(selectedColumnsCopy);







  }
  const handleUpdateClick = async () => {
    let newErrors = [];
    // Vérifier que `ref` a bien une valeur
    if (!selectedColumns.ref.value) {
      newErrors.push("La colonne Référence doit être sélectionnée.");
    }

    // Vérifier qu'au moins une autre colonne a une valeur
    const otherColumns = Object.keys(selectedColumns).filter((key) => key !== "ref");
    const hasAnotherValue = otherColumns.some((key) => selectedColumns[key].value);
    if (!hasAnotherValue) {
      newErrors.push("Au moins une autre colonne doit être sélectionnée.");
    }
    // Vérifier qu'aucune valeur n'est dupliquée
    const values = Object.values(selectedColumns).map((col) => col.value).filter((val) => val !== "");
    const uniqueValues = new Set(values);
    if (values.length !== uniqueValues.size) {
      newErrors.push("Chaque colonne doit être unique.");
    }
    if (newErrors.length > 0) {
      swalAlert(newErrors.join('<br />'));
      return;
    }
    // Toute erreur filtrée
    setFetching(true);
    const payload = {
      selectedColumns,
      uploadedFile
    }
    API.post(`/admin/products/updateFromFile`, { ...payload })
      .then(response => {
        console.log('Update success:', response.data);

        let message = 'Import du fichier "' + uploadedFile.name + '"<br /><br />';
        message += 'Nombre de produits mis à jour : ' + response.data.updated

        swalAlert(message);
        setUploadedFile(null);
        setSelectedColumns({ ...initialSelectedColumns });
      })
      .catch(error => {
        swalAlert(error.response.data.error.message);
        console.log('Error:', error);
      }).finally(() => {
        setFetching(false);
      });
    return;
  }

  const onUploadFailure = () => {
    console.log('Upload failed');
  };

  const renderColumnSelect = (field, label) => (




    <select
      value={selectedColumns[field].value}
      className='form-control form-control-xs'
      onChange={e => handleColumnSelect(field, e.target.value)}
    >
      <option value="">Ignorer</option>
      {Object.entries(fileHeaders)
        .filter(([_, name]) => name !== null)
        .map(([key, name]) => (
          <option key={key} value={key}>
            [{key}] {name}
          </option>
        ))}
    </select>

  );
  return (
    <div className="admin-home-update position-relative pb-2">
      <h4 className="">Mise à jour du catalogue</h4>
      <div className='content fs-8' style={{ 'opacity': fetching === true ? '0.5' : '1' }}>
        {uploadedFile === null ?
          <div className="d-flex justify-content-between">
            <div className="fw-bold">Télécharger le fichier csv ou xlsx</div>
            <Uploader
              inputProps={{ accept: "." + config.uploads.ldec.allowed_extensions.join(",.") }}
              upload_id='ldec'
              onUploadStart={data => onUploadStart(data)}
              onUploadSuccess={data => onUploadSuccess(data)}
              onUploadFailure={data => onUploadFailure(data)}
            />
          </div>
          :
          <div className=''>
            <div className='mb-3'>
              <label className='fw-bold mb-2'>Fichier téléchargé :</label>
              <div className='d-flex mb-3 bg-light py-1 px-2'>
                <div className='flex-grow-1'>
                  {uploadedFile.name}
                </div>
                <div>
                  <MdCancel className="pointer" title="Annuler" onClick={e => setUploadedFile(null)} />
                </div>
              </div>
            </div>
            <div className='mb-3'>
              <label className='fw-bold  mb-2'>Sélection les colonnes :</label>
              {fileHeaders && (
                <table className="w-100">
                  <tbody>
                    {Object.keys(selectedColumns).map((field) => (
                      <tr key={field}> {/* Ajoute une clé unique pour React */}
                        <td className='bg-light  py-1 px-2'>{selectedColumns[field].label}</td>
                        <td className='bg-light  py-1 px-2'>{renderColumnSelect(field)}</td> {/* Supprime "return" ici */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

            </div>
            <div className='pt-2 text-center d-flex justify-content-between'>
              <span style={{ width: '30px' }}></span>
              <button
                className='btn btn-sm btn-green'
                disabled={selectedColumns.ref.value == "" || fetching}
                onClick={handleUpdateClick}
              >Mettre à jour</button>
              <span style={{ width: '30px' }}>
                {fetching == false && <MdCancel title="Annuler" className="pointer" onClick={e => setUploadedFile(null)} />}
              </span>
            </div>


          </div>
        }








      </div>

      {fetching === true &&
        <div
          className='position-absolute h-100 w-100 pt-4 d-flex flex-column justify-content-center align-items-center'
          style={{ top: 0, left: 0 }}>
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Traitement en cours...</span>
          </div>
        </div>}
    </div>
  );
};

export default AdminHomeUpdate;
