import React, { useState, useEffect } from 'react';
import ProductSearchBar from './../products/ProductSearchBar';
import ProductModal from '../products/ProductModal';
import FeaturedProducts from './../products/FeaturedProducts';
import ExpressOrder from '../orders/ExpressOrder';
import LastProducts from './../products/LastProducts';
import { useSelector, useDispatch } from 'react-redux';
import { FaSearch } from "react-icons/fa"; // Import de l'icône de loupe

import { useNavigate } from "react-router-dom";

import './home-main.css';
import { debug } from './../../utils';



const HomeMain = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const user = useSelector((state) => state.user);
  const [showProductModal, setShowProductModal] = useState(false);
  const navigate = useNavigate();
  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };
  useEffect(() => {
    if (selectedProduct) {
      setShowProductModal(true);
    }
  }, [selectedProduct]);

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setShowProductModal(false);
  };
  const handleAdvancedSearchClick = () => {
    navigate('/catalog')
  }
  return (
    <div id="home-main">
      <div className="page-title">
        <h1>Bienvenue dans l'extranet clients</h1>
      </div>
      <div className='top-section'>
        <ProductSearchBar onSelect={handleProductSelect} />
      </div>
      <div className='center-section pt-3'>
        <div className="container">
          <div className="row">
            {user && user?.catalogs?.includes('Kit') &&
              <div className="col col-md-8 col-12 mb-5 py-2">
                <div className="module">
                  <LastProducts onSelectProduct={handleProductSelect} />
                  {/* <FeaturedProducts onSelectProduct={handleProductSelect} /> */}
                </div>
              </div>
            }
            <div className="col col-md-4 col-12 mb-3 pt-2 right-side">
              <div className="module">
                <div className='text-center mx-auto'>
                  <div onClick={handleAdvancedSearchClick}>
                    <h5
                      className="rounded pointer"
                      title="Rechercher par constructeurs, modèles, etc"


                    >
                      <FaSearch size={28} style={{ with: '30px' }} /><span> Recherche avancée</span>
                    </h5>
                  </div>
                </div>       </div>

              {(user && user.orderEnabled && user.catalogs?.includes('Faisceau')) &&
                <div className='module'>
                  <div className=' bg-light border rounded overflow-hidden '>
                    <ExpressOrder />
                  </div>
                </div>
              }
              <div className=' module'>
                <div className='py-3 px-4 bg-light border rounded text-center'>
                  Nous contacter :<br />
                  <strong className='fs-5 text-green'>+33 2 32 90 16 94</strong>
                </div>
              </div>
              <div className=' module'>
                <div className=' py-3 px-4 bg-light border rounded text-center '>
                  <a href="https://amd-jaeger.com/msmedias/extranet/guide-extranet-client-2025.pdf" className='text-green decoration-on-hover' target='_blank'>
                    Guide d'utilisation de la nouvelle version
                  </a>
                </div>

              </div>
              <div className=' module text-center'>
                <a href="https://amd-jaeger.com/mentions-legales-876e" className='small decoration-on-hover' target='_blank'>
                  Mentions légales
                </a>
              </div>


            </div>


          </div>
        </div>
      </div>
      {showProductModal && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}
    </div >
  );
};

export default HomeMain;
