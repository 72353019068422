import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debug, download, file, swalAlert } from '../../utils';
import API from '../../api';
import { useSelector } from 'react-redux';
import AdminOrdersList from './../orders/admin/AdminOrdersList';
import AdminOrdersChart from './../orders/admin/AdminOrdersChart';
import AdminHomeUpdate from '../products/admin/AdminHomeUpdate'
import QRCodeGenerator from '../products/admin/QRCodeGenerator';
import { FaCircle } from 'react-icons/fa';
import './admin-home.css';
function AdminHome() {
  const { t } = useTranslation();
  return (
    <div id="admin-home" className='container'>
      <div className="row">
        <div className="main col-lg-8">
          <div className="module mb-4 border border-gray-9 " >
            <h4 className="">Dernières commandes</h4>
            <AdminOrdersList
              year={new Date().getFullYear()}
              limit={25}
              title={false}
              selector={false}
              thead={false}
              columns={['orderDate', 'orderRef', 'customer', 'articles', 'orderTotalPriceIT', 'download']}
            /></div>
        </div>
        <div className="side col mb-5">
          <div className="module border border-gray-9 mb-4   bg-gray-e">
            <AdminHomeUpdate />
          </div>
          <div className="module border border-gray-9 mb-4 bg-gray-e">
            <QRCodeGenerator />
          </div>
          <div className="module border border-gray-9 mb-4 bg-gray-e">
            <div className="content fs-8">
              <p className="my-2"><FaCircle size={6} className="me-2" /><Link className="text-green" to="/extradmin/customers/new">Ajouter un compte client</Link></p>
              <p className="my-2"><FaCircle size={6} className="me-2" /><Link className="text-green" to="/extradmin/products/new">Ajouter un produit</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div >);
}
export default AdminHome;
