// Order.js
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debug, formatPrice, formatDate } from './../../utils';
import OrderItem from './OrderItem';
import './order-summary.css';
import './order-items.css';
import API from '../../api';
const Order = () => {

  const { id } = useParams();

  const [order, setOrder] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    if (id) {
      fetchOrder(id);
    }
  }, [id]);
  useEffect(() => {
    console.log(order)
  }, [order]);
  const fetchOrder = async (orderId) => {
    try {
      const response = await API.get(`/order/${orderId}`);
      setOrder(response.data);
    } catch (e) {
      console.log(e.message)
    }

  }
  const handleItemClick = () => { }
  return (
    <div id="order-details" className='' >

      {order.orderId &&
        <div>
          <div className='page-title'>
            <h1>Commande n°{order.orderRef}</h1>
          </div>
          <div className='container'>
            <div className='mb-3'>
              <span className='fw-bold'>Commande n° {order.orderRef} {order.orderInternalRef != '' && <span>/ {order.orderInternalRef}</span>}</span><br />
              <table className=''>
                <tbody>
                  <tr><td>Date</td><td className='px-2'>:</td><td> {formatDate(order.orderDate)}</td></tr>
                  <tr><td>HT</td><td className='px-2'>:</td><td>{formatPrice(order.orderTotalPriceET)} EUR</td></tr>
                  <tr><td>TVA</td><td className='px-2'>:</td><td>{formatPrice(order.orderVATAmount)} EUR</td></tr>
                  <tr><td>TTC</td><td className='px-2'>:</td><td> {formatPrice(order.orderTotalPriceIT)} EUR</td></tr>

                </tbody>
              </table>

            </div>


            {order.items.length > 0 &&
              <>
                <table className='table table-striped mb-5 text-center'>
                  <thead>
                    <tr className="">
                      <th>{t('REF')}</th>
                      <th>{t('Desc')}</th>
                      <th>{t('PU')}</th>
                      <th>{t('Qty')}</th>
                      <th>{t('HT')}</th>
                      <th>{t('TVA')}</th>
                      <th>{t('TTC')}</th>

                    </tr>
                  </thead>
                  <tbody>
                    {order.items.map(item => (
                      <OrderItem key={item.itId} item={item} onUpdate={e => { }} onClick={handleItemClick} editable={false} />
                    ))}
                  </tbody>
                  <tfoot>

                  </tfoot>
                </table>

              </>
            }


            {debug(order)}


          </div>
          {order.delivery &&
            <div className='col-md-3 col-sm-6'>
              <p className='mb-1'><strong>Adresse de livraion :</strong></p>
              {order.delivery?.daCompName != '' && <>{order.delivery.daCompName}<br /></>}
              {order.delivery?.daName} {order.delivery.daFirstname}<br />
              {order.delivery?.daAddress}<br />
              {order.delivery?.daPostCode} {order.delivery.daCity}<br />
              {order.delivery?.daCountry}<br />
              {order.delivery?.daEmail}<br />
              {order.delivery?.daPhone1} <br />
              {order.delivery?.daPhone2}
            </div>
          }</div>}




    </div >

  );
};
export default Order;
/*


{
    "orderId": "ORD6230abbee0090",
    "orderUserId": "user56519edd43c76",
    "orderTotalPriceET": "289",
    "orderVATAmount": "57.8",
    "orderTotalPriceIT": "346.8",
    "orderDeliveryId": "DA56f6571e9f601",
    "orderInternalRef": "",
    "orderDate": "2022-03-15 16:07:42",
    "formatedNumber": "",
    "items": [
        {
            "itId": "IT6230aad05c7cf",
            "itUserId": "user56519edd43c76",
            "itOrderId": "ORD6230abbee0090",
            "itProdId": "1",
            "itQuantity": 1,
            "itCatUnitPriceET": "185",
            "itMbrUnitPriceET": "100",
            "itMbrTotalPriceET": "100",
            "itVATRate": "20",
            "itVATAmount": "20",
            "itMbrTotalPriceIT": "120",
            "product": {
                "prodId": "1",
                "prodRef": "12280001",
                "prodCatalogue": "Faisceau",
                "prodMarque": "ALFA",
                "prodModele": "147 également facelift 2005",
                "prodStandard": "non",
                "prodType": "7",
                "prodAnnee": "10\/00-",
                "prodPrix": "200",
                "prodTitre": "",
                "prodMemo": "",
                "prodDatasheet": "\/msmedias\/datasheets\/12\/12280001.pdf",
                "prodFeatured": "0",
                "prodStatut": "supprimé",
                "prodPicture": "",
                "availableYears": [
                    2000,
                    2025
                ],
                "prodPrevPrix": "185\r",
                "prodNewPrix": "200",
                "prodAutodiagnostic": "●",
                "prodLEDSupport": "●",
                "prodLampeRemplacement": "–",
                "prodAideProgrammation": "–",
                "prodSpecifique": "",
                "prodClignotant": "AFC",
                "prodClouplage": "–",
                "prodCheck": "●",
                "prdPdc": "●",
                "prdPlus": "–",
                "prodLigne": "–",
                "prodCan": "–",
                "prodAlimentation": "–",
                "prodSocket": "",
                "prodTypeRotule": "",
                "prodCodeEAN": "4250060502532",
                "prodPoids": "",
                "prodMR": "",
                "prodPoidsSurFleche": "",
                "prodCodeTarif": "",
                "prodDeposePC": "",
                "prodDecoupePC": "",
                "prodPercage": "",
                "prodRothop": "",
                "prodAdaptSPV": "",
                "prodMontage": "A",
                "prodDatasheet2": "\/msmedias\/datasheets\/12\/12280001.pdf",
                "prodDatasheet3": "\/msmedias\/datasheets\/12\/12280001.pdf",
                "prodCreation": "0000-00-00",
                "prodUpdate": "0000-00-00 00:00:00",
                "prodNewsletters": "1",
                "prodDatasheetError": "0",
                "prodCodeStock": "0",
                "prodEmplStock": "",
                "prodRefBoitier": "",
                "prodRefPrise": "",
                "prodFlashControl": ""
            },
            "itProdRef": "12280001",
            "itCreatedAt": "2022-03-15 16:03:44",
            "itUpdatedAt": "",
            "itOrderYear": "2022",
            "itOrderYearMonth": "202203"
        },
        {
            "itId": "IT6230aae2701e0",
            "itUserId": "user56519edd43c76",
            "itOrderId": "ORD6230abbee0090",
            "itProdId": "43137",
            "itQuantity": 1,
            "itCatUnitPriceET": "189",
            "itMbrUnitPriceET": "189",
            "itMbrTotalPriceET": "189",
            "itVATRate": "20",
            "itVATAmount": "37.8",
            "itMbrTotalPriceIT": "226.8",
            "product": {
                "prodId": "43137",
                "prodRef": "12280502",
                "prodCatalogue": "Faisceau",
                "prodMarque": "ALFA",
                "prodModele": "156 Berline",
                "prodStandard": "non",
                "prodType": "",
                "prodAnnee": "10\/97-",
                "prodPrix": "210",
                "prodTitre": "",
                "prodMemo": "",
                "prodDatasheet": "\/msmedias\/datasheets\/122\/12280502J-Alfa-Romeo-156-Limousine-ab-10-97-Stand-04.11.05-1.pdf",
                "prodFeatured": "0",
                "prodStatut": "disponible",
                "prodPicture": "",
                "availableYears": [
                    1997,
                    2025
                ],
                "prodPrevPrix": "189\r",
                "prodNewPrix": "204",
                "prodAutodiagnostic": "●",
                "prodLEDSupport": "-",
                "prodLampeRemplacement": "-",
                "prodAideProgrammation": "-",
                "prodSpecifique": "",
                "prodClignotant": "",
                "prodClouplage": "-",
                "prodCheck": "-",
                "prdPdc": "●",
                "prdPlus": "",
                "prodLigne": "",
                "prodCan": "",
                "prodAlimentation": "Ar\/Av",
                "prodSocket": "",
                "prodTypeRotule": "",
                "prodCodeEAN": "4250060528860",
                "prodPoids": "",
                "prodMR": "",
                "prodPoidsSurFleche": "",
                "prodCodeTarif": "",
                "prodDeposePC": "",
                "prodDecoupePC": "",
                "prodPercage": "",
                "prodRothop": "",
                "prodAdaptSPV": "",
                "prodMontage": "B",
                "prodDatasheet2": "",
                "prodDatasheet3": "",
                "prodCreation": "2010-04-21",
                "prodUpdate": "0000-00-00 00:00:00",
                "prodNewsletters": "0",
                "prodDatasheetError": "0",
                "prodCodeStock": "1",
                "prodEmplStock": "",
                "prodRefBoitier": "",
                "prodRefPrise": "",
                "prodFlashControl": ""
            },
            "itProdRef": "12280502",
            "itCreatedAt": "2022-03-15 16:04:02",
            "itUpdatedAt": "",
            "itOrderYear": "2022",
            "itOrderYearMonth": "202203"
        }
    ],
    "customer": null,
    "delivery": null,
    "orderPDFFilename": "\/msmedias\/orders\/2022\/03\/W023048-ORD6230abbee0090.pdf",
    "orderStatus": "validée",
    "orderRef": "23048",
    "orderStId": "",
    "orderTitle": ""
}

*/