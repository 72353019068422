import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import API from '../../api';
import { FaCheckCircle } from 'react-icons/fa'; // Icône de sélection
import './product-selector-modal.css';
const ProductSelectorModal = ({ prodRef = '', onHide, onSelect }) => {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(true);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterMarque, setFilterMarque] = useState('');
  const [filterModele, setFilterModele] = useState('');
  // Fonction pour normaliser les chaînes (supprime accents et met en minuscule)
  const normalizeString = (str) => {
    return str
      ? str
        .toLowerCase()
        .normalize('NFD') // Décompose les caractères accentués
        .replace(/[\u0300-\u036f]/g, '') // Supprime les accents
      : '';
  };
  // Récupération des produits depuis l'API
  useEffect(() => {
    if (!prodRef) return;
    setProducts([]);
    const qs = 'query=' + JSON.stringify({ 'prodRef:eq': prodRef });


    API.get('/products?' + qs)
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => console.error('Erreur API :', error))
      .finally(() => {
        setFetching(false);
      });
  }, [prodRef]);
  // Filtrage dynamique
  useEffect(() => {
    const filtered = products.filter(product =>
      normalizeString(product.prodMarque).includes(normalizeString(filterMarque)) &&
      normalizeString(product.prodModele).includes(normalizeString(filterModele))
    );
    setFilteredProducts(filtered);
  }, [filterMarque, filterModele, products]);
  const handleOnHide = () => {
    onHide && onHide();
  }
  const handleSelect = (product) => {
    onSelect && onSelect(product);
  }
  return (
    <Modal show={true} onHide={handleOnHide} className="modal" keyboard={true} id="ProductSelectorModal">
      <Modal.Header closeButton>
        <div className='row flex-grow-1'>
          <div className='col-sm-8'>
            <Modal.Title>{t('Ref')} {prodRef}</Modal.Title>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 className='mb-3'>Sélectionner un produit :</h5>
        <table className='table table-sm table-striped'>
          <thead>
            <tr>
              <th style={{ width: '160px' }}>
                Constructeurs
                <input
                  type="text"
                  className="form-control form-control-xs"
                  value={filterMarque}
                  onChange={(e) => setFilterMarque(e.target.value)}
                />
              </th>
              <th style={{ width: '160px' }}>
                Modèles
                <input
                  type="text"
                  className="form-control form-control-xs"
                  value={filterModele}
                  onChange={(e) => setFilterModele(e.target.value)}
                />
              </th>
              <th>Années</th>
              <th style={{ width: '40px' }}></th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product, index) => (
              <tr key={product.id || `${prodRef}-${index}`}>
                <td>{product.prodMarque}</td>
                <td>{product.prodModele}</td>
                <td className='text-nowrap'>{product.prodAnnee}</td>
                <td >
                  <FaCheckCircle size={16} onClick={() => handleSelect(product)} className='pointer check text-gray-9' />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {fetching && <div class="spinner-border m-5" role="status">
          <span class="visually-hidden">Chargement en cours...</span>
        </div>}
      </Modal.Body>
    </Modal>
  );
};
export default ProductSelectorModal;
