
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { loadCart } from '../../redux/cartSlice';
import InputAutocomplete from '../helpers/InputAutocomplete/InputAutocomplete';
import ExpressorderIcon from './ExpressOrderIcon';
import API from '../../api';
import './express-order.css';
import { swalAlert, debug } from '../../utils';
const ExpressOrder = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [references, setReferences] = useState([]);
  const [ref, setRef] = useState('');
  const [qty, setQty] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const qs = `query=${JSON.stringify({ 'prodCatalogue:eq': 'Faisceau' })}`;
    API.get(`/references?${qs}`).then(response => {
      const uniqueReferences = response.data.map(item => ({ name: item }));
      setReferences(uniqueReferences);
    }).catch(error => {
      console.log(error);
    });
  }, []);



  useEffect(() => {
    console.log(items);
  }, [items]);
  const handleAddItem = async () => {
    if (!ref.trim() || qty <= 0) {
      swalAlert("Veuillez entrer une référence valide et une quantité supérieure à 0.");
      return;
    }
    try {

      API.handleError = false;
      try {
        const response = await API.get(`/reference/${ref}`);
        setItems(prevItems => [...prevItems, { ref, qty }]);

        setRef('');  // Réinitialiser le champ ref après ajout
        setQty(1);   // Réinitialiser la quantité

      } catch (error) {
        swalAlert(error.response.data.error.message)
        console.log(error);
      }

      return;
    } catch (error) {
      alert(error.message);
    }
  };
  const handleRemoveItem = (index) => {
    setItems(prevItems => prevItems.filter((_, i) => i !== index));
  };
  const handleOrder = async () => {
    dispatch(loadCart());
    try {
      const response = await API.post(`/express_order`, items);
      console.log(response.data);
      navigate('/cart');
    } catch (error) {
      swalAlert(error.response.data.error.message)
      console.log(error);
    }
  }
  const handleSuggestionChange = (event) => {
    const { name, value } = event.target;
    setRef(value);
  };
  const handleSuggestionSelect = (name, value) => {
    setRef(value.name);
  };
  return (
    <div id="order-express">
      <h5 className='text-green'>
        <ExpressorderIcon />
        <span>Commande express de faisceaux</span></h5>
      <div className='py-2 px-2'>
        <p className='mb-1'>Saisissez la référence et la quantité, puis cliquez sur +.</p>
        <div className="d-flex input-group-sm mb-2  py-1">
          <InputAutocomplete
            inputClassName='form-control-sm'
            className="w-66"
            disabled={false}
            readonly={false}
            suggestionsSrc={references}
            suggestionsOnFocus={true}
            name='ref'
            placeholder=''
            value={ref}
            displayKey="name"
            onChange={handleSuggestionChange}

            handleOnSuggestsChange={() => { }}
            onSuggestionSelect={handleSuggestionSelect}
            formatSuggestion={(key, reference) => reference.name}

            debug={false}

          />

          <input
            type="number"
            className="form-control"
            placeholder="Quantité"
            value={qty}
            min="1"
            style={{ width: '35px' }}
            onChange={(e) => setQty(parseInt(e.target.value, 10) || 1)}
          />
          <button className="btn btn-green" style={{ width: '30px' }} onClick={handleAddItem}>+</button>
        </div>

        <ul className="mb-3">
          {items.map((item, index) => (
            <li key={index} className='d-flex align-items-center'>
              <div className='flex-grow-1 ps-2'>
                {item.ref}
              </div>
              <div className='text-center' style={{ width: '35px' }} >
                {item.qty}
              </div>
              <div className='pe-2 text-end' style={{ width: '30px' }}>

                <FaTrash size={12} onClick={() => handleRemoveItem(index)} className="text-black-50 pointer" />


              </div>
            </li>
          ))}
        </ul>
        {items.length > 0 &&
          <div className="text-center">
            <button className="btn btn-sm btn-green" onClick={handleOrder}>Commander</button>
          </div>
        }
        {false && debug(items)}
      </div>
    </div>
  );
};

export default ExpressOrder;
