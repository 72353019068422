const ExpressorderIcon = ({ size = 48 }) => (
   <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" >

      <path d="M27.038,20.563H9.592l0.876-1.784l14.555-0.026c0.492,0,0.914-0.352,1.002-0.838L28.04,6.633
	c0.053-0.296-0.026-0.601-0.22-0.832c-0.193-0.23-0.479-0.364-0.779-0.366L8.525,5.373L8.367,4.629
	c-0.1-0.475-0.527-0.82-1.014-0.82H2.827c-0.571,0-1.034,0.463-1.034,1.034s0.463,1.034,1.034,1.034h3.688l0.691,3.287l1.702,8.241
	l-2.191,3.577c-0.232,0.313-0.266,0.731-0.088,1.078c0.176,0.349,0.53,0.568,0.923,0.568h1.84C9,23.15,8.789,23.784,8.789,24.437
	c0,1.658,1.348,3.006,3.006,3.006s3.006-1.348,3.006-3.006c0-0.653-0.217-1.289-0.604-1.808h4.72
	c-0.392,0.521-0.604,1.155-0.604,1.808c0,1.658,1.348,3.006,3.006,3.006s3.006-1.348,3.006-3.006c0-0.653-0.217-1.289-0.604-1.808
	h3.319c0.568,0,1.034-0.463,1.034-1.034C28.072,21.024,27.608,20.564,27.038,20.563z M8.956,7.412l16.869,0.056l-1.652,9.252
	l-13.251,0.023L8.956,7.412z M11.795,25.362c-0.51,0-0.926-0.416-0.926-0.926s0.416-0.926,0.926-0.926s0.926,0.416,0.926,0.926
	C12.721,24.947,12.306,25.362,11.795,25.362z M21.319,25.362c-0.51,0-0.926-0.416-0.926-0.926s0.416-0.926,0.926-0.926
	s0.926,0.416,0.926,0.926C22.245,24.947,21.83,25.362,21.319,25.362z" stroke="none" fill="#ffffff" />
      <line fill="none" stroke="#ffffff" x1="2.19" y1="9.682" x2="6.19" y2="9.682" />
      <line fill="none" stroke="#ffffff" x1="2.19" y1="11.657" x2="6.69" y2="11.657" />
      <line fill="none" stroke="#ffffff" x1="2.19" y1="13.632" x2="7.69" y2="13.632" />
   </svg>
)
export default ExpressorderIcon;